header {
  text-align: center;
  margin: 32px 0;
}
@media (min-width: 768px) {
  /* Adjust the breakpoint as needed */
  header {
    margin: 84px 0;
  }
}

.app {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}
.news-item {
  display: flex;
  cursor: pointer;
  /* max-width: 300px; */
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* For the first news item, you want to override and make it a row */
.news-item.first {
  flex-direction: row;
  /* align-items: center; */
  justify-content: start;
  /* max-height: 362px; */
  /* max-width: none; */
  flex: 1;
  display: flex;
  margin: 20px 0px;
}

.dot {
  border-radius: 100px;
  background: #0fb597;
  width: 6px;
  height: 6px;
  align-self: center;
  margin: 0 8px;
}

/* This will style the content of the first item */
.news-item.first .news-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0px 24px 32px 24px;

  /* Make sure the details fill the space after the image */
}

/* This will ensure that the image does not stretch in the first item */
.news-item.first .news-image {
  aspect-ratio: 412 / 322;
  object-fit: cover;
  height: 362px;
  width: 30%;
  /* object-position: center; */
  /* margin-right: 20px; Space between image and text */
}

/* This will ensure that the image does not stretch in the first item */
.news-item .news-image {
  width: 100%; /* Or set to a fixed width */
  /* padding: 0; */
  aspect-ratio: 370 / 240;
  object-fit: cover;
}

/* For non-first items, ensure the details fill the item */
.news-item:not(.first) .news-details {
  /* width: 100%; */
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding: 0px 0px 32px 0px;
}

@media (max-width: 480px) {
  .news-item.first {
    flex-direction: column; /* Stack the first item on small screens */
  }
  .news-item.first .news-image {
    aspect-ratio: 412 / 322;
    object-fit: cover;
    width: 100%;
    /* object-position: center; */
    /* margin-right: 20px; Space between image and text */
  }
  .news-item.first .news-details {
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    width: unset;
    padding: 0px 24px 32px 24px;

    /* Make sure the details fill the space after the image */
  }
}

.news-keywords {
  color: var(--Brand-Green, #0fb597);
  font-family: Sora;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 120% */
  letter-spacing: -0.75px;
  text-transform: uppercase;
}
.news-title {
  color: var(--brand-black, #191919);
  align-self: stretch;
  font-family: Sora;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 121.429% */
  letter-spacing: -1.4px;
  margin: 16px 0;
  text-wrap: wrap;
  display: block;
  padding-left: 24px;
  padding-right: 24px;
}
.news-summary {
  color: #707070;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.14px;
  align-self: stretch;
  margin: 0 0 0 0;
  padding-left: 24px;
  padding-right: 24px;
}
.news-top {
  color: #0fb597;
  margin: 32px 0 0 0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.14px;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 24px;
  padding-right: 24px;
}
.news-bottom {
  color: #0fb597;
  margin: 16px 0 0 0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.14px;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 24px;
  padding-right: 24px;
}

/* Styling for the search form */
.search-form {
  display: flex;
  padding: 8px 9px 8px 17px;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  border-radius: 12px;
  flex: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  background: #fff;
}
.search-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  max-width: 800px;
}
/* Styling for the input field */
.search-form input {
  color: #000;

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  flex: 1 0 0;
  border-width: 0;
}
.search-form input:focus {
  outline: none;
}
/* Styling for the search button */
.search-form button {
  border-radius: 8px;
  background: #0fb597;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  outline: none;
  border-style: none;
}

.search-form button:hover {
  background: #0fb597;
  outline: none;
}

.Labs {
  margin-top: 8px;
}

.loaderParent {
  flex: 1;
  width: 100%;
  margin-top: 64px;
  display: flex;
  justify-content: center;
}
.loader {
  width: 200px;
  height: 200px;
  justify-self: center;
  align-self: center;
}

.news-list {
  margin: 16px 32px;
}
.news-list-container {
  padding: 16px 32px;
  max-width: 1200px;
}

@media (min-width: 768px) {
  /* Adjust the breakpoint as needed */
  .news-list {
    margin: 32px 64px;
  }
}
/* underlined, full width, aligned to right */
.prompt-preview {
  cursor: pointer;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.15px;
  letter-spacing: -2%;
  color: #707070;
  margin: 16px 0 0 0;
  text-decoration: underline;
  text-align: right;
}

.prompt-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.prompt-modal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  width: 80%;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Center children horizontally in the container */
  justify-content: center; /* Optional: If you also want to center children vertically */

  border-radius: 8px;
}

.prompt-modal-header {
  margin-bottom: 20px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: #191919;
}

.prompt-modal-prompt,
.prompt-modal-query {
  background-color: #e0e0e0;
  border: none;
  padding: 16px;
  margin-bottom: 20px;
  border-radius: 8px;
  resize: none;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #000000;
  width: 100%; /* Full width of the parent container */
  box-sizing: border-box; /* Include padding and border in the width calculation */
}

.prompt-modal-button {
  cursor: pointer;
  background-color: #0fb597;
  color: white;
  border: none;
  font-family: Inter;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  padding: 16px 32px 16px 32px;
}

.prompt-close-button {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 32px;
  font-size: 28px;
  font-weight: bold;
}

.prompt-modal-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.prompt-close-button:hover,
.prompt-close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* full height, all content centred horizontally and padding top 60px */
.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
}
.loginButton {
  height: 48px;
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
}

.loginText {
  font-family: Sora;
  font-weight: 600;
  font-size: 15px;
  line-height: 18.9px;
  color: #0fb597;
}
/* 
Placed in top right part of the screen
*/
.settings {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border: none;
}
/* Font
Sora
Weight
600
Size
15px
Line height
18.9px
color: #0FB597 
 */

.settingsLabel {
  font-family: Sora;
  font-weight: 600;
  font-size: 15px;
  line-height: 18.9px;
  color: #0fb597;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 640px;
  text-align: center;
}
/* div container horizontal wrapping items  */
.interests {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}

/* height:33 px, radius-8px, padding 8px,16px,8px,16px , border 0.5px,  Weight 400, size 14px, line height 17px, font:Inter, color:#191919  */
.interest-tag {
  height: 33px;
  border-radius: 8px;
  padding: 0px 16px 0px 16px;
  border: 0.5px solid #191919;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #191919;
  cursor: pointer;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
/* interest-tag when selected: backgroundColor: #f6f6f6, border: 1.5px   */
.interest-tag.selected {
  background-color: #f6f6f6;
  border: 1.5px solid #191919;
}

.add-interest-input {
  height: 43px;
  gap: 8px;
  flex: 1;
  padding: 0 10px;
  font-family: Inter;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  border: 0.5px solid #191919;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
/* when add-interest-input is focused it should have red border color */
.add-interest-input:focus {
  outline: none;
}

.add-interest-btn {
  font-family: Sora;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: white;
  height: 43px;
  background-color: #0fb597;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
}

.add-interest input {
  padding: 10px;
  margin-right: 5px;
  border: 1px solid #ccc;
  border-radius: 20px;
}

.continue-btn,
.skip-btn {
  margin-top: 20px;
  padding: 10px 40px;
  border: none;
  height: 43px;
  cursor: pointer;
  color: white;
}

.skip-btn {
  background-color: transparent;
  border: none;
  color: #0fb597;
  font-family: Sora;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
}
.continue-btn {
  font-family: Sora;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: white;
  background-color: #0fb597;
  border: none;
  border-radius: 8px;
}

.modal-content-title {
  font-family: Sora;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-align: left;
  color: #191919;
}
.modal-content-text {
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #707070;
  text-align: left;
}
